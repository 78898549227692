import React from 'react' // eslint-disable-line no-use-before-define
import { dispatch } from '../../lib/events'

// Components
import GalleryProvider from '../../providers/GalleryProvider'
import AddressButton from './AddressButton'
import { ExampleLink, MainText, SecondaryHeader } from '../../content/copy'
import * as footer from '../../content/footer'
import TrackedLink from './TrackedLink'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './LandingMobile.module.sass'

const Landing = () => {
  return (
    <div className={styles.wrapper}>

      <div className={styles.content}>

        {/* Title */}
        <div className={styles.title}>
          <span className={styles.highlight}>NFT</span> Gallery
          <img className={styles.betaTag} src="/images/beta_tag.svg"></img>
        </div>

        {/* Introduction */}
        <div className={styles.introduction}>
          Connect and experience immersive<br/>
          exhibitions of NFT Collections
        </div>

        {/* Buttons */}
        <div className={styles.buttons}>
          <GalleryProvider>
            <AddressButton />
          </GalleryProvider>
        </div>

        <div className={styles.exampleLink}>
          <ExampleLink />
        </div>

        {/* Background image */}
        <div className={styles.backgroundImage}></div>
        <div className={styles.backgroundPlaceholder}></div>

        {/* Secondary title */}
        <div className={styles.secondaryTitle}>
          <SecondaryHeader />
        </div>

        {/* Text */}
        <div className={styles.text}>
          <MainText />
        </div>

        {/* Dividing line */}
        <div className={styles.divider}></div>

        {/* Footer */}
        <div className={styles.footer}>
          {/* Links */}
          <div className={styles.footerLinks}>
            { footer.links.map((link, index) =>
              <TrackedLink key={index} url={link.url} eventParams={{ category: 'footer', target: link.title }}>
                <div className={styles.footerLinksLink}>{link.title}</div>
              </TrackedLink>
            )}
          </div>
          <div className={styles.footerRow}>
            {/* Social */}
            <div className={styles.footerSocial}>
              { footer.icons.map((icon, index) =>
                <TrackedLink key={index} url={icon.url} eventParams={{ category: 'footer', target: icon.service }}>
                  <img className={styles.footerSocialIcon} src={icon.path}/>
                </TrackedLink>
              )}
            </div>
            {/* Powered by hiber */}
            <div className={styles.footerPoweredBy}>
              <a href="https://hiberworld.com" target="_blank" rel="noreferrer" onClick={() => dispatch('click_hiberworld_logo')}>
                <img className={styles.footerPoweredByImage} src="/images/powered_by_hiberworld.svg" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom filler */}
        <div className={styles.filler}></div>
      </div>
    </div>
  )
}

export default Landing
