import React from 'react' // eslint-disable-line no-use-before-define
import TrackedLink from '../components/Landing/TrackedLink'

export const MainText = () => <>
  NFTs are exciting to own, but they are even more fun to show off to your friends and the world.
  Here you have the opportunity to generate a unique 3D gallery based on the contents of any wallet.
  Enter any Ethereum address or ENS name, or connect your own wallet, to view NFTs held by that account.
  Explore the gallery, share it with friends, learn more about the NFTs and view them on Opensea!
  <br/><br/>
  Want to have even more fun? Check out <TrackedLink url="https://hiberworld.com">hiberworld.com</TrackedLink>,
  where more than 3.5 million virtual worlds have been created by users just like you.

  <br/><br/>
  Find out more about Hiber, the company behind this project, at <TrackedLink url="https://hiber.mv">hiber.mv</TrackedLink>.
</>

export const ExampleLink = () => <>
    {"Don't"} know where to start? Try an <TrackedLink url="/baleas.eth" eventParams={{ category: 'demo' }} target="_self">example gallery</TrackedLink>
  </>

export const SecondaryHeader = () => <>Welcome to NFT Gallery</>
