import React, { useState } from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'
import { generateIconURL } from '../../lib/util'
import classNames from 'classnames/bind'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './Button.module.sass'

const cn = classNames.bind(styles)

export type ButtonProps = {
  // Left icon
  iconLeft?: string,
  iconLeftClickHandler?: () => void,

  // Right icon
  iconRight?: string,
  iconRightText?: string
  iconRightDisabled?: boolean
  iconRightHidden?: boolean
  iconRightClickHandler?: () => void,
  iconRightAlwaysHidden?: boolean

  // Other
  text?: string
  showInput?: boolean
  label?: string,
  handleInput?: (event: KeyboardEvent) => void
  inputValue?: string
}

const Button = (props) => {
  // Hooks
  const [hover, setHover] = useState(false)

  const inputFocused = document.activeElement.id === 'address-input'

  return (
    <div className={cn('button', { hover: hover && !inputFocused, showInput: props.showInput })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* Left icon */}
      <img
        src={generateIconURL(props.iconLeft, 'default')}
        className={styles.iconLeft}
        onClick={(e) => {
          if (props.iconLeftClickHandler) {
            e.stopPropagation()
            props.iconLeftClickHandler && props.iconLeftClickHandler()
          }
        }}
      />

      {/* Show label? */}
      {props.label
        ? <div className={styles.labeled}>
        <div className={styles.label}>{props.label}</div>

            {/* Show input field or text? */}
            {props.showInput
              ? <input
                  type="text"
                  id="address-input"
                  className={styles.labeledInput}
                  autoFocus
                  onChange={e => props.handleInput(e)}
                  value={props.inputValue}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      props.iconRightClickHandler && props.iconRightClickHandler()
                    }
                  }}
                />
              : <div className={styles.labeledText}>{props.text}</div>}
          </div>
        : <div className={styles.text}>{props.text}</div>
      }

      {/* Show icon to the right? */}
      {props.iconRightHidden || props.iconRightAlwaysHidden
        ? null
        : <>
        {/* Icon text */}
        <div
            className={cn('iconRightText', { disabled: props.iconRightDisabled })}
            onClick={() =>
              props.iconRightClickHandler &&
              !props.iconRightDisabled &&
              props.iconRightClickHandler()
            }
          >{props.iconRightText}</div>
        {/* Icon */}
        <img
          src={generateIconURL(props.iconRight, 'default')}
          className={cn('iconRight', { disabled: props.iconRightDisabled })}
          onClick={(e) => {
            if (props.iconRightClickHandler && !props.iconRightDisabled) {
              e.stopPropagation()
              props.iconRightClickHandler()
            }
          }}/>
        </>
        }
    </div>
  )
}

Button.propTypes = {
  iconLeft: PropTypes.string,
  iconLeftClickHandler: PropTypes.func,
  iconRight: PropTypes.string,
  iconRightText: PropTypes.string,
  iconRightClickHandler: PropTypes.func,
  iconRightDisabled: PropTypes.bool,
  iconRightHidden: PropTypes.bool,
  iconRightAlwaysHidden: PropTypes.bool,
  showInput: PropTypes.bool,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  handleInput: PropTypes.func
}

export default Button
