import React, { useEffect, useState } from 'react' // eslint-disable-line no-use-before-define
import { Helmet } from 'react-helmet'
import { isMobile } from 'react-device-detect'
import { isBrowser } from '../lib/util'

import LandingDesktop from '../components/Landing/LandingDesktop'
import LandingMobile from '../components/Landing/LandingMobile'

const Index = () => {
  if (!isBrowser()) return (<></>)

  const [landingPage, setLandingPage] = useState(null)

  useEffect(() => {
    isMobile
      ? setLandingPage(LandingMobile)
      : setLandingPage(LandingDesktop)
  }, [])

  return (
    <>
      {/* Page title and meta tags */}
      <Helmet>
        <title>NFT Gallery</title>
        <link rel="icon" type="image/png" href="/images/hiber_head.png" />
        <meta property="og:title" content="NFT Gallery" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Immersive NFT galleries" />
        <meta property="og:image" content="https://nftgallery.mv/images/social.jpg" />
        <meta property="og:url" content={location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      { landingPage }
    </>
  )
}

export default Index
