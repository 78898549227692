import React, { useContext, useEffect, useState } from 'react' // eslint-disable-line no-use-before-define
import Button, { ButtonProps } from './Button'
import { ButtonType, GalleryContext } from '../../providers/GalleryProvider'
import { isENS, truncateAddress } from '../../lib/util'
import { isMobile } from 'react-device-detect'

enum ButtonState {
  DEFAULT,
  ENTER_ADDRESS,
  READY_TO_LAUNCH
}

let previousState: ButtonState

const AddressButton = () => {
  const gallery = useContext(GalleryContext)
  const [buttonState, setButtonState] = useState(ButtonState.DEFAULT)
  const [inputValue, setInputValue] = useState('')

  // KLUDGE ALERT! 👇
  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === 'Enter' && buttonState === ButtonState.READY_TO_LAUNCH) gallery.launch()
    }
    document.addEventListener('keypress', handleEnter)
    return () => document.removeEventListener('keypress', handleEnter)
  }, [buttonState])

  const handleClick = () => {
    if (buttonState === ButtonState.DEFAULT) setButtonState(ButtonState.ENTER_ADDRESS)
    if (buttonState === ButtonState.READY_TO_LAUNCH) gallery.launch()
  }

  const handleInput = (e) => {
    e.preventDefault()
    const input = e.target.value.toLowerCase()
    setInputValue(input)
    gallery.setAddress(input)
  }

  const generateButtonProps = (state: ButtonState): ButtonProps => {
    // STATE: DEFAULT
    if (state === ButtonState.DEFAULT) {
      if (previousState !== state) gallery.setAddress('')
      gallery.setExpandedButton(null)
      return {
        iconLeft: 'link',
        iconRight: 'arrow_expand',
        text: 'Enter Ethereum address',
        showInput: false
      }
    }

    // STATE: ENTER ADDRESS
    if (state === ButtonState.ENTER_ADDRESS) {
      gallery.setExpandedButton(ButtonType.KEYBOARD)
      // Generate label according to address validity
      const generateLabel = () => {
        if (gallery.address === '') return 'Ethereum Address or ENS name'
        else if (gallery.isValidAddress) return 'Address is valid'
        else return 'Enter valid address'
      }

      return {
        iconLeft: 'arrow_contract',
        iconLeftClickHandler: () => {
          setButtonState(ButtonState.DEFAULT)
          setInputValue('')
        },
        iconRightClickHandler: () => {
          if (gallery.isValidAddress) setButtonState(ButtonState.READY_TO_LAUNCH)
        },
        iconRight: 'arrow_next',
        iconRightText: 'NEXT',
        showInput: true,
        label: generateLabel(),
        iconRightDisabled: !gallery.isValidAddress,
        iconRightHidden: gallery.address === '',
        iconRightAlwaysHidden: isMobile,
        handleInput,
        inputValue
      }
    }

    // STATE: READY TO LAUNCH
    if (state === ButtonState.READY_TO_LAUNCH) {
      // If full ethereum address, truncate to e.g. '0xabcd...01234'
      const generateAddress = () => isENS(gallery.address) ? gallery.address : truncateAddress(gallery.address)

      return {
        iconLeft: 'arrow_contract',
        iconLeftClickHandler: () => setButtonState(ButtonState.ENTER_ADDRESS),
        iconRight: 'play',
        iconRightText: 'PLAY',
        showInput: false,
        label: generateAddress(),
        text: 'Enter experience'
      }
    }
    previousState = state
  }

  const buttonProps = generateButtonProps(buttonState)

  return (
    <div onClick={() => handleClick()}>
      <Button {...buttonProps}/>
    </div>
  )
}

export default AddressButton
