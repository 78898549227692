import React, { useState } from 'react' // eslint-disable-line no-use-before-define
import PropTypes from 'prop-types'
import { isENS, isAddress } from '../lib/util'
import { dispatch } from '../lib/events'
import { resolveAddress } from '../lib/ens'

// Types
export enum ButtonType {
  KEYBOARD = 'keyboard',
  WALLET = 'wallet'
}

export type GalleryProvider = {
  address: string,
  expandedButton: ButtonType,
  isValidAddress: boolean,
  setAddress: (address: string) => void,
  setExpandedButton: (button: ButtonType) => void
  launch: () => void
}

// Context
const defaults: GalleryProvider = {
  address: '',
  expandedButton: null,
  isValidAddress: false,
  setAddress: () => null,
  setExpandedButton: () => null,
  launch: () => null
}
export const GalleryContext = React.createContext(defaults)

// Utility functions
const isValid = (address: string) => isAddress(address) || isENS(address)

// Provider component
const GalleryProvider = (props) => {
  const [address, setAddress] = useState(defaults.address)
  const [expandedButton, setExpandedButton] = useState(defaults.expandedButton)
  const [isValidAddress, setIsValidAddress] = useState(defaults.isValidAddress)

  // If address validity changed -> set new validity
  if (isValid(address) !== isValidAddress) setIsValidAddress(isValid(address))

  // Callback to launch gallery
  const launch = async () => {
    try {
      await resolveAddress(address)
      dispatch('action_launch', { address, inputMethod: expandedButton })
      window.open(`/${address}`, '_self')
    } catch (err) {
      dispatch('error_invalid_address', { address, inputMethod: expandedButton })
      alert(`Failed to resolve ENS name: ${address}`)
    }
  }

  const value = {
    address,
    expandedButton,
    isValidAddress,
    launch,
    setAddress,
    setExpandedButton
  }

  return (
    <GalleryContext.Provider value={value}>
      {props.children}
    </GalleryContext.Provider>
  )
}

GalleryProvider.propTypes = {
  children: PropTypes.node
}

export default GalleryProvider
