import React from 'react' // eslint-disable-line no-use-before-define
import GalleryProvider from '../../providers/GalleryProvider'
import Buttons from './Buttons'
import * as footer from '../../content/footer'
import { ExampleLink, MainText, SecondaryHeader } from '../../content/copy'
import TrackedLink from './TrackedLink'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './LandingDesktop.module.sass'

const Landing = () => {
  return (
    <div className={styles.wrapper}>

      {/* BACKGROUND */}
      <div className={styles.backgroundImage}></div>

      {/* HEADER */}
      <div className={styles.header}>

        <div className={styles.hiberworld}>
          <TrackedLink url="https://hiberworld.com"><img src="/images/powered_by_hiberworld.svg" /></TrackedLink>
        </div>
        {/* <div className="row-header-feedback">
          <TrackedLink url="https://forms.gle/L2g3JGQTsHwUjc1J6" eventParams={{ category: 'feedback' }}><img src="/images/feedback.svg"/></TrackedLink>
        </div> */}
      </div>

      {/* MAIN CONTENT */}
      <div className={styles.content}>
        <div className={styles.contentColumn}>
          {/* Title */}
          <div className={styles.title}>
            <span className={styles.highlight}>NFT</span> Gallery
          </div>

          {/* Beta tag */}
          <img className={styles.betaTag} src="/images/beta_tag.svg"></img>

          {/* Introduction */}
          <div className={styles.introduction}>
            Connect and experience immersive<br/>
            exhibitions of NFT Collections
          </div>

          {/* Buttons */}
          <div className={styles.buttons}>
            <GalleryProvider>
              <Buttons />
            </GalleryProvider>
          </div>

          {/* Demo link */}
          <div className={styles.demoLink}>
            <ExampleLink />
          </div>

          {/* Secondary title */}
          <div className={styles.secondaryTitle}>
            <SecondaryHeader />
          </div>

          {/* Text */}
          <div className={styles.text}>
            <MainText />
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className={styles.footer}>

        {/* Links */}
        <div className={styles.links}>
          { footer.links.map((link, index) =>
            <TrackedLink key={index} url={link.url} eventParams={{ category: 'footer', target: link.title }}>
              <div className={styles.link}>{link.title}</div>
            </TrackedLink>
          )}
        </div>

        {/* Social links */}
        <div className={styles.social}>
          { footer.icons.map((icon, index) =>
            <TrackedLink key={index} url={icon.url} eventParams={{ category: 'footer', target: icon.service }}>
              <img className={styles.socialIcon} src={icon.path}/>
            </TrackedLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default Landing
