export type FooterLink = {
  title: string,
  url: string,
}

export type FooterSocialIcon = {
  service: string
  path: string,
  url: string
}

export const links: FooterLink[] = [
  { title: 'Privacy Policy', url: 'https://hiber.mv/privacy-policy-nftg' },
  { title: 'Terms of Use', url: 'https://hiber.mv/terms-of-use-nftg' },
  { title: 'Cookies', url: 'https://hiber.mv/cookie-policy-nftg' },
  { title: 'Disclaimer', url: 'https://hiber.mv/disclaimer-nftg' },
  { title: 'Feedback', url: 'https://forms.gle/L2g3JGQTsHwUjc1J6' }
]

export const icons: FooterSocialIcon[] = [
  { service: 'Facebook', path: '/icons/social/facebook.png', url: 'https://www.facebook.com/hibergame' },
  { service: 'Twitter', path: '/icons/social/twitter.png', url: 'https://twitter.com/hiberofficial' },
  { service: 'Discord', path: '/icons/social/discord.png', url: 'https://discord.com/invite/xt9Wprq' },
  { service: 'Instagram', path: '/icons/social/instagram.png', url: 'https://www.instagram.com/hiberofficial/' },
  { service: 'Youtube', path: '/icons/social/youtube.png', url: 'https://www.youtube.com/c/HiberWorld' }
]
