import React, { useContext, useState, useEffect } from 'react' // eslint-disable-line no-use-before-define
import Button, { ButtonProps } from './Button'
import { ButtonType, GalleryContext } from '../../providers/GalleryProvider'
import Web3Modal from 'web3modal'
import { ethers } from 'ethers'
import { truncateAddress } from '../../lib/util'
import { dispatch } from '../../lib/events'

enum ButtonState {
  DEFAULT = 'default',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CONNECTION_FAILED = 'connectionFailed'
}

let previousState: ButtonState

// TODO: This file needs to be tidied up properly!

const WalletButton = () => {
  const gallery = useContext(GalleryContext)
  const [buttonState, setButtonState] = useState(ButtonState.DEFAULT)
  const [web3modal, setWeb3Modal] = useState(null)
  const [instance, setInstance] = useState(null)

  // Metamask interaction
  // TODO: Move this stuff to the provider
  useEffect(() => {
    const web3modal = new Web3Modal({
      network: 'mainnet',
      cacheProvider: true,
      providerOptions: {}
    })
    setWeb3Modal(web3modal)
  }, [])

  // If provider cached in localStorage -> connect automatically
  useEffect(() => {
    if (
      web3modal &&
      localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER')
    ) {
      // TODO: Handle automatic connection
      // connectWallet()
    }
  }, [web3modal])

  const connectWallet = async () => {
    // Get address
    const instance = await web3modal.connect()
    const provider = new ethers.providers.Web3Provider(instance)
    const signer = provider.getSigner()
    const address = await signer.getAddress()

    // Setup event listeners
    instance.on('accountsChanged', (accounts: string[]) => {
      gallery.setAddress(accounts[0])
    })

    // Update state
    gallery.setAddress(address)
    setInstance(instance)
    dispatch('action_wallet_connected', { address, provider: 'metamask' })
  }

  const disconnectWallet = () => {
    // Clear event listeners
    instance.removeAllListeners()

    // Clear cached provider
    web3modal.clearCachedProvider()

    // Update component state
    gallery.setAddress('')
    setInstance(null)
    dispatch('action_wallet_disconnected', { address: gallery.address, provider: 'metamask' })
  }

  const handleClick = async () => {
    // DEFAULT
    if (buttonState === ButtonState.DEFAULT) {
      setButtonState(ButtonState.CONNECTING)
      try {
        await connectWallet()
        setButtonState(ButtonState.CONNECTED)
      } catch (err) {
        setButtonState(ButtonState.CONNECTION_FAILED)
      }
    }

    // CONNECTING
    if (buttonState === ButtonState.CONNECTING) setButtonState(ButtonState.CONNECTED)

    // CONNECTED
    if (buttonState === ButtonState.CONNECTED) gallery.launch()
  }

  const generateButtonProps = (state: ButtonState): ButtonProps => {
    // STATE: DEFAULT
    if (state === ButtonState.DEFAULT) {
      if (previousState !== state) {
        gallery.setAddress('')
        gallery.setExpandedButton(null)
      }
      return {
        iconLeft: 'wallet',
        iconRight: 'arrow_expand',
        text: 'Connect your wallet',
        showInput: false
      }
    }

    // STATE: CONNECTING
    if (state === ButtonState.CONNECTING) {
      gallery.setExpandedButton(ButtonType.WALLET)

      return {
        iconLeft: 'arrow_contract',
        iconLeftClickHandler: () => setButtonState(ButtonState.DEFAULT),
        iconRightHidden: true,
        showInput: false,
        label: 'Connecting wallet',
        text: '__________ __ ___ __ __ _ _'
      }
    }

    // STATE: CONNECTED
    if (state === ButtonState.CONNECTED) {
      return {
        iconLeft: 'wallet_disconnect',
        iconLeftClickHandler: () => {
          disconnectWallet()
          setButtonState(ButtonState.DEFAULT)
        },
        showInput: false,
        label: 'Wallet connected',
        text: `${truncateAddress(gallery.address)}`,
        iconRight: 'play',
        iconRightText: 'PLAY'
      }
    }

    // STATE: CONNECTION FAILED
    if (state === ButtonState.CONNECTION_FAILED) {
      // if (previousState !== state) gallery.setAddress('')
      return {
        iconLeft: 'arrow_contract',
        iconLeftClickHandler: () => setButtonState(ButtonState.DEFAULT),
        showInput: false,
        text: 'Failed to connect',
        iconRight: 'retry',
        iconRightText: 'RETRY',
        iconRightClickHandler: async () => {
          setButtonState(ButtonState.CONNECTING)
          try {
            await connectWallet()
            setButtonState(ButtonState.CONNECTED)
          } catch (err) {
            setButtonState(ButtonState.CONNECTION_FAILED)
          }
        }
      }
    }

    // Store previous state
    previousState = state
  }

  const buttonProps = generateButtonProps(buttonState)

  return (
    <div onClick={() => handleClick()}>
      <Button {...buttonProps}/>
    </div>
  )
}

export default WalletButton
