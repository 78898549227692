// extracted by mini-css-extract-plugin
export var highlight = "LandingMobile-module--highlight--mD1UZ";
export var wrapper = "LandingMobile-module--wrapper--2zeKC";
export var content = "LandingMobile-module--content--1WpF2";
export var title = "LandingMobile-module--title--3XRJe";
export var betaTag = "LandingMobile-module--beta-tag--3wkN5";
export var introduction = "LandingMobile-module--introduction--13dD_";
export var buttons = "LandingMobile-module--buttons--3pOpO";
export var exampleLink = "LandingMobile-module--example-link--3_J6r";
export var backgroundImage = "LandingMobile-module--background-image--14EPS";
export var backgroundPlaceholder = "LandingMobile-module--background-placeholder--3aRdj";
export var secondaryTitle = "LandingMobile-module--secondary-title--37SPk";
export var text = "LandingMobile-module--text--1JZYG";
export var divider = "LandingMobile-module--divider--3y6xx";
export var footer = "LandingMobile-module--footer--3jdTa";
export var footerLinks = "LandingMobile-module--footer-links--1lQQp";
export var footerRow = "LandingMobile-module--footer-row--bCPTN";
export var footerSocial = "LandingMobile-module--footer-social--3Puaj";
export var footerSocialIcon = "LandingMobile-module--footer-social-icon--3ShT8";
export var footerPoweredBy = "LandingMobile-module--footer-powered-by--3mkpT";
export var footerPoweredByImage = "LandingMobile-module--footer-powered-by-image--3e4DP";
export var footerLinksLink = "LandingMobile-module--footer-links-link--2KMC9";
export var filler = "LandingMobile-module--filler--3OERQ";