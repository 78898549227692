import React, { useContext } from 'react' // eslint-disable-line no-use-before-define
import { ButtonType, GalleryContext } from '../../providers/GalleryProvider'
import AddressButton from './AddressButton'
import WalletButton from './WalletButton'
import { isMobile } from 'react-device-detect'

// @ts-expect-error Conversion from .sass to .css done during build
import * as styles from './Buttons.module.sass'

const Buttons = () => {
  const gallery = useContext(GalleryContext)

  const showAddressButton = gallery.expandedButton === ButtonType.KEYBOARD || !gallery.expandedButton
  const showWalletButton = gallery.expandedButton === ButtonType.WALLET || !gallery.expandedButton

  return (
    <div className={styles.buttons}>
      {showAddressButton ? <AddressButton/> : null}
      {showWalletButton && !isMobile ? <WalletButton/> : null}
    </div>
  )
}

export default Buttons
