import React from 'react' // eslint-disable-line no-use-before-define
import { dispatch } from '../../lib/events'
import PropTypes from 'prop-types'

const DEFAULT_EVENT = 'clicked_link'
const DEFAULT_TARGET = '_blank'

const TrackedLink = ({ children, event, eventParams, target, url }) =>
  <a
    href={url}
    target={target || DEFAULT_TARGET}
    rel="noreferrer"
    onClick={() => dispatch(event || DEFAULT_EVENT, { url, ...eventParams })}
    >{children}
  </a>

TrackedLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  target: PropTypes.string,
  event: PropTypes.string,
  eventParams: PropTypes.object,
  url: PropTypes.string
}

export default TrackedLink
