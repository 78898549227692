// extracted by mini-css-extract-plugin
export var wrapper = "LandingDesktop-module--wrapper--3jfmk";
export var highlight = "LandingDesktop-module--highlight--1tzw9";
export var backgroundImage = "LandingDesktop-module--background-image--1FTUo";
export var header = "LandingDesktop-module--header--2gNIQ";
export var hiberworld = "LandingDesktop-module--hiberworld--3O9mI";
export var content = "LandingDesktop-module--content--3Z2oD";
export var contentColumn = "LandingDesktop-module--content-column--18xMb";
export var betaTag = "LandingDesktop-module--beta-tag--3b4k9";
export var title = "LandingDesktop-module--title--28yLL";
export var introduction = "LandingDesktop-module--introduction--ckp5B";
export var buttons = "LandingDesktop-module--buttons--3VJ8Y";
export var demoLink = "LandingDesktop-module--demo-link--1xNGY";
export var secondaryTitle = "LandingDesktop-module--secondary-title--3F8CV";
export var text = "LandingDesktop-module--text--3WUVx";
export var footer = "LandingDesktop-module--footer--cCnRi";
export var links = "LandingDesktop-module--links--2Z_pz";
export var social = "LandingDesktop-module--social--3Rp3j";
export var socialIcon = "LandingDesktop-module--social-icon--1Ygdj";